import axios from "axios";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function CarrierSetup() {
  const [checkboxOptions, setCheckboxOptions] = useState({
    boxtruck: false,
    hotshot: false,
    dryvan: false,
    reefer: false,
    flarbed: false,
    stepdeck: false,
    rgn: false,
  });

  const [selectionCount, setSelectionCount] = useState(0);
  const [firstnamee, setFirstnamee] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mcnumber, setMcnumber] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [streetaddress, setstreetaddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setstate] = useState("");
  const [Zipcode, setZipcode] = useState("");
  const [numberoftrucks, setNumberoftrucks] = useState(0);
  const [preferences, setPreferences] = useState("");
  const [agrement, setAgrement] = useState(false);
  const [files, setFiles] = useState([]);

  const [dragActive, setDragActive] = useState(false);

  const handleFileChange = (e) => {
    setFiles("")
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);

  };
  const handleChange = (event) => {
    const { name, checked } = event.target;

    const newCount = checked ? selectionCount + 1 : selectionCount - 1;

    if (newCount <= numberoftrucks) {
      setCheckboxOptions((prev) => ({
        ...prev,
        [name]: checked,
      }));
      setSelectionCount(newCount);
    }
  };

  const sendMessage = async (formdata) => {
    try {
      toast.loading("Submitting data.");
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const url = `/api/v1/carriersetup/messages`;

      const { data } = await axios.post(url, formdata, config);

      if (data.success) {
        toast.dismiss();
        toast.success("Data submitted.");
        setFirstnamee("");
        setLastname("");
        setMcnumber("");
        setCompanyname("");
        setEmail("")
        setPhonenumber("")
        setstreetaddress("")
        setCity("")
        setstate("")
        setZipcode("")
        setNumberoftrucks(0)
        setCheckboxOptions({
          boxtruck: false,
          hotshot: false,
          dryvan: false,
          reefer: false,
          flarbed: false,
          stepdeck: false,
          rgn: false,
        })
        setSelectionCount(0)
        setFiles([])
        setPreferences("")
        setAgrement(false)
        setNumberoftrucks("")

      
      }
    } catch (error) {
      
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", firstnamee+" "+lastname);
    formData.append("email", email);
    formData.append("mcNumber", mcnumber);
    formData.append("companyName", companyname);
    formData.append("telephone", phonenumber);
    formData.append("address", streetaddress);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("zipCode", Zipcode);
    formData.append("noOfTrucks", numberoftrucks);
    const checkedTrucks = Object.keys(checkboxOptions).filter(
      (truck) => checkboxOptions[truck]
    );
    
    checkedTrucks.forEach((truck) => {
      
      formData.append("trucks", truck);
    });

   
    files.forEach((file) => {
      formData.append(`docs`, file);
    });

    formData.append("preferences", preferences);
console.log([...formData])
    await sendMessage(formData);
  };

  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    
  },[])

  return (
    <div>
      {/* Image Div */}
      <div
        className=" mt-20  h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] "
        style={{
          backgroundColor: "black",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className=" relative h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] bg-cover flex justify-center items-center bg-center  ">
          <div>
            <div
              className="absolute top-0 left-0 bg-cover opacity-40 h-full w-full"
              style={{
                backgroundImage: `url(https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/bwjubxuozefkqfbmroi6)`,
              }}
            ></div>
          </div>
        </div>

        {/* Heading */}

        <div className="absolute   top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-default     z-50 uppercase  w-fit space-y-5 text-center  h-f">
          <div>
            <div className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#dcfce7)] [-webkit-text-fill-color:transparent] inline-block text-[32px]  sm:text-3xl md:text-4xl lg:text-6xl xl:text-8xl text-center w-full tracking-tighter  ">
              Carrier Setup
            </div>
            <div className="font-bold font-[inherit]  inline-block text-green-100 text-[15px] sm:text-md md:text-xl lg:text-2xl xl:text-3xl text-center w-full tracking-tighter  ">
              Cargo dispatchers
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="isolate  px-6 py-24 sm:py-32 lg:px-8">
        <div
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          aria-hidden="true"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight  sm:text-4xl">
            Join Now
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            CARRIER STARTUP WITH US
          </p>
        </div>
        <form
          onSubmit={handleFormSubmit}
          method="post"
          className="mx-auto mt-16 max-w-xl sm:mt-20"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-semibold leading-6 "
              >
                First name*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  placeholder="First Name"
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={firstnamee}
                  onChange={(e) => {
                    setFirstnamee(e.target.value);
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-semibold leading-6 "
              >
                Last name*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="last-name"
                  id="last-name"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                  autoCorrect="family-name"
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="mcnumber"
                className="block text-sm font-semibold leading-6 "
              >
                MC Number*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="mcnumber"
                  id="mcnumber"
                  placeholder="MC Number"
                  value={mcnumber}
                  onChange={(e) => {
                    setMcnumber(e.target.value);
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-semibold leading-6 "
              >
                Company Name*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="last-name"
                  id="last-name"
                  placeholder="Company Name"
                  value={companyname}
                  onChange={(e) => {
                    setCompanyname(e.target.value);
                  }}
                  autoCorrect="family-name"
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 "
              >
                Email*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="name@gmail.com"
                  autoCorrect="email"
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="phone-number"
                className="block text-sm font-semibold leading-6 "
              >
                Phone number*
              </label>
              <div className="relative mt-2.5">
                <input
                  required
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  placeholder="Phone Number"
                  autoCorrect="tel"
                  value={phonenumber}
                  onChange={(e) => {
                    setPhonenumber(e.target.value);
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 "
              >
                Street Address*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="company"
                  placeholder="Street Address"
                  id="company"
                  value={streetaddress}
                  onChange={(e) => {
                    setstreetaddress(e.target.value);
                  }}
                  autoCorrect="organization"
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold leading-6 "
              >
                City*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="city"
                  id="city"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-semibold leading-6 "
              >
                State*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="text"
                  name="state"
                  id="state"
                  value={state}
                  onChange={(e) => {
                    setstate(e.target.value);
                  }}
                  placeholder="State"
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            {/* Zipcode */}
            <div>
              <label
                htmlFor="zipcode"
                className="block text-sm font-semibold leading-6 "
              >
                Zip Code / Postal Code*
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  required
                  name="zipcode"
                  placeholder="Zip Code"
                  id="zipcode"
                  value={Zipcode}
                  onChange={(e) => {
                    setZipcode(e.target.value);
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>{" "}
            {/* NUmber of trucks */}
            <div>
              <label
                htmlFor="trucks"
                className="block text-sm font-semibold leading-6 "
              >
                Number of Trucks / Drivers*
              </label>
              <div className="mt-2.5">
                <input
                  required
                  type="number"
                  name="trucks"
                  id="trucks"
                  placeholder="0"
                  value={numberoftrucks < 1 ? "" : numberoftrucks}
                  onChange={(e) => {
                    setNumberoftrucks(e.target.value);
                    setCheckboxOptions({
                      boxtruck: false,
                      hotshot: false,
                      dryvan: false,
                      reefer: false,
                      flarbed: false,
                      stepdeck: false,
                      rgn: false,
                    })
                    setSelectionCount(0)
                  }}
                  className="w-full h-12  placeholder-gray-400 shadow-sm bg-transparent text-lg font-normal leading-7 rounded-full border border-gray-200 focus:outline-none pl-4 mb-10"
                />
              </div>
            </div>
            {/* Radio Buttons */}
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 "
              >
                Select Trucks*
              </label>
              <div className="mt-2.5 flex flex-wrap">
                {Object.keys(checkboxOptions).map((truck) => (
                  <label key={truck} className="mr-4">
                    <input
                      type="checkbox"
                      name={truck}
                      checked={checkboxOptions[truck]}
                      onChange={handleChange}
                    />
                    <span className="pl-2">
                      {truck.charAt(0).toUpperCase() + truck.slice(1)}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            {/* File */}
            <div className="flex flex-col   items-center justify-center">
              <label
                htmlFor="filesInput"
                className={`w-full h-48 flex flex-col items-center justify-center border-2 border-dashed  rounded-lg cursor-pointer ${
                  dragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="text-center p-5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-gray-400 mb-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 16V8a4 4 0 014-4h10a4 4 0 014 4v8M16 12l-4-4m0 0l-4 4m4-4v12"
                    />
                  </svg>
                  <p className="text-gray-600">
                    Drag & drop your PDF files here, or{" "}
                    <span className="text-blue-500 underline">browse</span>
                  </p>
                </div>
              </label>

              <input
                type="file"
                name="files"
                required
                id="filesInput"
                accept=".pdf"
                multiple
                className="hidden"
                onChange={handleFileChange}
              />

              {files.length > 0 && (
                <div className="mt-4 w-full">
                  <h4 className="text-lg font-semibold mb-2">
                    Selected Files:
                  </h4>
                  <ul className="list-disc pl-5">
                    {files.map((file, index) => (
                      <li
                        key={index}
                        className="text-gray-700 dark:text-gray-300 flex items-center"
                      >
                        <span>{file.name}</span>
                        <button
                          type="button"
                          className="ml-2 text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveFile(index)}
                        >
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* Message */}
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 "
              >
                Preferences*
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  value={preferences}
                  onChange={(e) => {
                    setPreferences(e.target.value);
                  }}
                  placeholder="Preferences"
                  className="w-full shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg  placeholder-gray-400 text-lg font-normal leading-7 bg-transparent rounded-2xl border border-gray-200 focus:outline-none pt-5 pl-5 mb-10"
                ></textarea>
              </div>
            </div>
            <div className="flex gap-x-4 sm:col-span-2">
              <div className="flex h-6 items-center">
                <input
                  required
                  type="checkbox"
                  checked={agrement}
                  onChange={() => {
                    setAgrement(!agrement);
                  }}
                />
              </div>
              <label
                className="text-sm leading-6 text-gray-600"
                id="switch-1-label"
              >
                By selecting this, you agree to our
                <Link
                  to="/privacy-policy"
                  className="font-semibold pl-2 underline text-indigo-600"
                >
                  privacy&nbsp;policy
                </Link>
                .
              </label>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:shadow-lg hover:scale-[1.009] focus:shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sumbit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CarrierSetup;
