import { useTheme } from "@/components/theme-provider";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function GettingStarted() {
  const containerRef = useRef();

  const { theme } = useTheme();
  const navigate = useNavigate();
  const isDark = theme === "dark";
  let container;
  useGSAP(
    () => {
      gsap.to(".animated-up-line-about", {
        scrollTrigger: {
          scroller: "body",
          trigger: "#getting-started",
          scrub: true,
          start: "top 60%",
        },
        y: "100%",
      });
      gsap.fromTo(".getting-animation-staggers", 
        { 
          y: 40, 
          opacity: 0,
          scale: 0.7
        }, 
        {
          y: 0, 
          opacity: 1,
          scale: 1, 
          duration: 1,  
          stagger: 0.2, 
          scrollTrigger: {
            scroller: "body",
            trigger: "#getting-started",
            start: "top 70%",
            end: "bottom top",
            onEnter: () => {
              gsap.fromTo(".getting-animation-staggers", 
                { 
                  y: 40, 
                  opacity: 0,
                  scale: 0.7 
                }, 
                {
                  y: 0, 
                  opacity: 1,
                  scale: 1, 
                  duration: 1,  
                  stagger: 0.2,
                }
              );
            },
           
          }
        }
      );
      
      
    },
    { scope: container }
  );

  useEffect(() => {
    container = containerRef.current;
  }, []);
  return (
    <div
      id="getting-started"
      ref={containerRef}
      className="getting-started relative xl:my-40 my-20  w-full py-20 lg:py-24 min-h-fit "
    >
      <div className="getting-started-container   w-[95%]   mx-auto px-1 md:px-5 space-y-12  ">
        {/* Getting started text */}
        <div
          className=" space-y-8 w-[90%] sm:w-[80%] md:w-[80%] lg:w-[70%]
        xl:w-[60%] mx-auto "
        >
          <div className="getting-animation-staggers heading uppercase text-center  text-xl text-[35px] sm:text-4xl md:text-6xl font-bold ">
            Getting{" "}
            <span className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#001adf)] [-webkit-text-fill-color:transparent] w-full leading-8 ">
              Started <br className="md:hidden" /> with us
            </span>{" "}
            is easy
          </div>
          <div className="getting-animation-staggers description-getting-started text-xl text-center">
            {
              "We’re dedicated to making your life as a trucker easier and stress-free. At Cargo Commandos, we don’t just gain clients we forge lasting partnerships, where your success fuels our success. Maximize your earnings with our expert dispatch services. Join us today and elevate your trucking experience!"
            }
          </div>
        </div>
        {/* Getting started Cards */}
        <div className="getting-started-cards">
          <div className="flex-col flex space-y-5 w-[70%] sm:w-[70%] md:w-[50%]  mx-auto lg:flex-row  lg:space-y-0  lg:w-[95%] xl:w-[70%] ">
            <div className="getting-animation-staggers w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg">
              <div className="text-4xl  w-fit mx-auto font-bold text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  id="upload-file"
                  width="48"
                  height="48"
                  fill="white"
                >
                  <path
                    fillRule="evenodd"
                    d="M102.021 8h247.803v48.401c0 11.169 9.076 20.245 20.245 20.245h48.401V420.66c0 4.676-3.814 8.491-8.491 8.491h-83.502v-53.466h18.425c8.273 0 15.68-4.765 19.11-12.293a20.924 20.924 0 0 0-3.262-22.485L271.848 238.66c-4.022-4.626-9.717-7.221-15.847-7.221-6.13 0-11.825 2.595-15.847 7.221l-88.902 102.247a20.922 20.922 0 0 0-3.262 22.485c3.43 7.528 10.837 12.293 19.11 12.293h18.425v53.466h-83.502c-4.677 0-8.491-3.814-8.491-8.491V16.491C93.53 11.814 97.344 8 102.021 8zm263.802.736v47.665a4.253 4.253 0 0 0 4.245 4.245h47.665a8.506 8.506 0 0 0-1.751-2.487L368.31 10.487a8.512 8.512 0 0 0-2.487-1.751zM159.841 89.654h138.056a8 8 0 0 0 0-16H159.841a8 8 0 0 0 0 16zm0 56.676h192.318a8 8 0 0 0 0-16H159.841a8 8 0 0 0 0 16zm0 56.677h192.318a8 8 0 0 0 0-16H159.841a8 8 0 0 0 0 16zM310.477 494V359.685h34.425c2.004 0 3.719-1.103 4.55-2.927s.538-3.841-.777-5.354l-88.902-102.247c-.984-1.131-2.274-1.719-3.773-1.719s-2.789.588-3.773 1.719l-88.902 102.247c-1.315 1.513-1.607 3.53-.777 5.354.831 1.824 2.546 2.927 4.55 2.927h34.425V494c0 5.508 4.492 10 10 10h88.955c5.507 0 9.999-4.492 9.999-10z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="text-lg uppercase w-fit mx-auto font-semibold text-white">
                Send Your Documents
              </div>
            </div>
            <div className="text-center lg:py-12  lg:-rotate-90   flex items-center justify-center h-20 lg:w-40  lg:h-full ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill={isDark ? "white" : "black"}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-down"
              >
                <path d="M12 5v14" />
                <path d="m19 12-7 7-7-7" />
              </svg>
            </div>
            <div className="getting-animation-staggers w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg">
              <div className="text-4xl w-fit mx-auto uppercase font-bold text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  id="agreement"
                  width="48"
                  height="48"
                >
                  <path
                    fill="white"
                    d="M20.38 10.86H39.8c.54 0 .97-.43.97-.97V5.34c0-.54-.43-.97-.97-.97H20.38c-.54 0-.97.43-.97.97v4.55c0 .54.43.97.97.97zm.97-4.55h17.48v2.61H21.35V6.31zm-.97 9.52H39.8c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H20.38a.97.97 0 0 0 0 1.94zm12.29 2.8c0-.54-.43-.97-.97-.97H20.38c-.54 0-.97.43-.97.97s.43.97.97.97H31.7c.54 0 .97-.44.97-.97z"
                  ></path>
                  <path
                    fill="white"
                    d="m62.5 48.04-2.36-5.76c-.31-.77-.95-1.36-1.77-1.64l-2.96-.88V9.59c0-.02-.01-.04-.01-.06-.01-.09-.02-.17-.05-.26l-.03-.09c-.05-.1-.11-.2-.19-.28L46.51.29c-.02-.02-.03-.03-.05-.04a.913.913 0 0 0-.23-.15c-.04-.02-.08-.03-.12-.04a.85.85 0 0 0-.22-.04c-.03-.01-.05-.02-.08-.02H17.79c-1.56 0-2.83 1.31-2.83 2.93v21.62l-6.72-7.2c-.36-.38-.84-.6-1.36-.62-.55-.03-1.02.17-1.4.52l-3.62 3.37a1.97 1.97 0 0 0-.1 2.77l2.61 2.8 10.58 11.35v14.7c0 1.62 1.27 2.93 2.83 2.93H47.6c.05.25.09.51.16.75l.24.9h-.69c-.54 0-.97.43-.97.97v5.24c0 .54.43.97.97.97H60.8c.54 0 .97-.43.97-.97v-5.24c0-.54-.43-.97-.97-.97h-.65l2.38-6.21c.32-.83.31-1.75-.03-2.57zM46.78 3.31l5.31 5.31h-4.42c-.49 0-.89-.44-.89-.99V3.31zM10.1 22.17 25 38.15l.03.03.39.42-1.11 1.04L8.99 23.21l1.11-1.04zm16.27 18.21 1.64 4.66-4.53-1.96 2.89-2.7zM3.18 22.03l3.64-3.37 1.95 2.09-3.63 3.39-1.96-2.11zm3.29 3.53 1.1-1.03 15.32 16.43L21.78 42 6.47 25.56zm41.27 24.12c-.33 1.16-.44 2.35-.4 3.55H17.79c-.5 0-.89-.44-.89-1V39.62l4.12 4.42c.09.1.2.18.32.23l7.92 3.42a.965.965 0 0 0 1.29-1.21l-1.28-3.65h14.97c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H28.61l-.86-2.43h16.51c.54 0 .97-.43.97-.97s-.43-.97-.97-.97H26.13l-9.23-9.9V2.93c0-.54.4-.99.89-.99h27.05v5.7c0 1.61 1.27 2.93 2.83 2.93h5.79V35c-.41-.16-.86-.24-1.31-.21-1.61.1-2.86 1.49-2.86 3.16l.01 3.52c-.02 1.88-.3 3.78-.83 5.64l-.73 2.57zm12.09 12.38H48.29v-3.3h11.54v3.3zm.89-12.15-2.65 6.91h-8.05l-.39-1.42c-.49-1.7-.5-3.49-.02-5.19l.73-2.6c.57-2.03.87-4.09.9-6.15l-.01-3.53c0-.64.47-1.18 1.04-1.22.32-.02.64.09.87.32.15.15.32.4.32.77v7.15c0 .54.43.97.97.97s.97-.43.97-.97v-3.17l2.38.7c.25.09.46.28.56.52l2.36 5.76c.15.38.16.78.02 1.15z"
                  ></path>
                  <path
                    fill="white"
                    d="M46.31 18.04c-1.55 0-2.81 1.26-2.81 2.81 0 .71.27 1.35.7 1.84l-1.86 3.4c-.13-.02-.27-.04-.41-.04-.78 0-1.48.32-1.99.82l-3.2-1.47c.01-.07.02-.15.02-.22 0-1.55-1.26-2.81-2.81-2.81s-2.81 1.26-2.81 2.81c0 .42.1.81.26 1.17l-1.58 1.57a2.76 2.76 0 0 0-1.18-.27c-1.55 0-2.81 1.26-2.81 2.81s1.26 2.81 2.81 2.81 2.81-1.26 2.81-2.81c0-.42-.1-.81-.26-1.17l1.58-1.57c.36.17.75.27 1.18.27.77 0 1.48-.31 1.99-.82l3.2 1.47c-.01.07-.02.15-.02.22 0 1.55 1.26 2.81 2.81 2.81s2.81-1.26 2.81-2.81a2.8 2.8 0 0 0-.7-1.84l1.86-3.4c.13.02.27.04.41.04 1.55 0 2.81-1.26 2.81-2.81s-1.26-2.81-2.81-2.81zm-17.65 13.3a.87.87 0 0 1 0-1.74c.24 0 .45.1.61.25h.01c.16.16.25.37.25.61 0 .49-.39.88-.87.88zm5.31-5.29c-.24 0-.45-.1-.61-.25 0 0-.01 0-.01-.01a.87.87 0 1 1 .62.26zm7.97 3.69a.87.87 0 0 1-.8-1.21c.01-.01.01-.02.02-.03.14-.3.44-.51.79-.51.48 0 .87.39.87.87-.01.49-.4.88-.88.88zm4.37-8.02a.87.87 0 1 1 .001-1.741.87.87 0 0 1-.001 1.741z"
                  ></path>
                </svg>
              </div>
              <div className="text-lg w-fit mx-auto uppercase font-semibold text-white">
                Sign Agrement
              </div>
            </div>
            <div className="text-center lg:py-12  lg:-rotate-90   flex items-center justify-center h-20 lg:w-40  lg:h-full ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill={isDark ? "white" : "black"}
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-down"
              >
                <path d="M12 5v14" />
                <path d="m19 12-7 7-7-7" />
              </svg>
            </div>
            <div className="getting-animation-staggers w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg">
              <div className="text-4xl w-fit mx-auto uppercase font-bold text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  id="truck"
                  fill="white"
                >
                  <path fill="none" d="M0 0h48v48H0z"></path>
                  <path d="M40 16h-6V8H6c-2.21 0-4 1.79-4 4v22h4c0 3.31 2.69 6 6 6s6-2.69 6-6h12c0 3.31 2.69 6 6 6s6-2.69 6-6h4V24l-6-8zM12 37c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm27-18 3.93 5H34v-5h5zm-3 18c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>
                </svg>
              </div>
              <div className="text-lg w-fit mx-auto uppercase font-semibold text-white">
                Start hauling loads
              </div>
            </div>
          </div>
        </div>
        {/* Sign Up Button */}
        <div className="getting-animation-staggers button w-full xl:w-[20%]  mx-auto flex justify-center  ">
          <button 
           onClick={() => {
            navigate("/carrier-setup");

            setTimeout(() => {
              window.scroll({
                top: 0,
                behavior: "smooth",
              });
            }, 200);
            
          }}
          className="more-about-us hover:text-white px-[35px] md:px-10 w-[90%] md:py-4 py-4 rounded-full text-xl  my-3 mx-auto bg-black dark:bg-white dark:text-black text-white z-40">
            Sign Up
          </button>
        </div>
      </div>

      {/* Animations Line 1  */}
      <div
        className="animated-down-line2 absolute top-0 left-0 overflow-hidden mx-1 md:mx-10  w-[3px] md:w-[5px]   "
        id="animation-for-aboutus"
        style={{
          borderRadius: "70%",

          height: "100%",
          backgroundColor: "blue",

          boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
        }}
      >
        <div
          id="animated-up-line-about"
          className={`animated-up-line-about w-full h-full absolute  bg-[#242424] `}
        ></div>
      </div>
      {/* Animations Line 2  */}
      <div
        className="animated-down-line2 absolute top-0 right-0 overflow-hidden mx-1 md:mx-10 w-[3px] md:w-[5px]  "
        id="animation-for-aboutus"
        style={{
          borderRadius: "70%",

          height: "100%",
          backgroundColor: "blue",

          boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
        }}
      >
        <div
          className={`animated-up-line-about w-full h-full absolute  bg-[#242424] `}
          id="animated-up-line-about"
        ></div>
      </div>
    </div>
  );
}

export default GettingStarted;
