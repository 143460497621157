import TruckOnRoad from "@/components/animations/TruckOnRoad";
import LandingPage from "../landingPage/LandingPage";
import WhoWeArePage from "../whoWeAre/WhoWeArePage";
import NumbersDetailPage from "../Numbers/NumbersDetailPage";
import GettingStarted from "../gettingStarted/GettingStarted";
import PricingPage from "../pricing/PricingPage";
import WhyUs from "../why-us/WhyUs";

function Home() {
  return (
    <div id="home">
      {" "}
      <LandingPage />
      <WhoWeArePage />
      <NumbersDetailPage />
      <div className="animation-truck-box   pt-[10vh]  pb-[7vh] lg:pb-[20vh]">
        <TruckOnRoad />
      </div>
      <GettingStarted />
      <WhyUs/>
      <PricingPage />
    </div>
  );
}

export default Home;
