import { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./landingPage.css";
import { useNavigate } from "react-router-dom";

export default function LandingPage() {
  //   const carouselRef = useRef(null);
  const cursorRef = useRef(null);
  const navigate = useNavigate()
  const [cursorDirection, setCursorDirection] = useState("right");

  //   const goToNext = () => {
  //     if (carouselRef.current) {
  //       carouselRef.current.moveTo(carouselRef.current.state.selectedItem + 1);
  //     }
  //   };
  //   const goToPrev = () => {
  //     if (carouselRef.current) {
  //       carouselRef.current.moveTo(carouselRef.current.state.selectedItem - 1);
  //     }
  //   };

  const images = [
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/lidv9e7ygrxjdigp2i41",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/dywwro5funi5y1m1trlf",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/bwjubxuozefkqfbmroi6",
  ];

  const [cursorHide, setcursorHide] = useState(false);
  useEffect(() => {
    const moveCursor = (e) => {
      if (cursorRef.current) {
        cursorRef.current.style.left = `${e.pageX}px`;
        cursorRef.current.style.top = `${e.pageY}px`;

        const pageWidth = window.innerWidth;
        if (e.pageX < pageWidth / 2) {
          setCursorDirection("left");
        } else {
          setCursorDirection("right");
        }
      }
    };

    const page = document.getElementById("LandingPage");
    if (page) {
      page.addEventListener("mousemove", moveCursor);

      page.addEventListener("mouseenter", () => {
        cursorRef.current.style.scale = 1;
      });
      page.addEventListener("mouseleave", () => {
        cursorRef.current.style.scale = 0;
      });
    }

    return () => {
      if (page) {
        page.removeEventListener("mousemove", moveCursor);
      }
    };
  }, []);

  return (
    <main>
      <div
        id="LandingPage"
        className="LandingPage mt-20  h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] "
        style={{
          backgroundColor: "black",
          position: "relative",
          zIndex: 1,
        }}
      >
        {/*Cursor  */}
        <div
          ref={cursorRef}
          id="custom-cursor"
          className={`custom-cursor !hidden mdd:!flex absolute  justify-center items-center font-bold text-black ${cursorHide?"opacity-0":""}`}
        >
          {cursorDirection === "right" ? (
            <svg
              role="presentation"
              focusable="false"
              width="30%"
              height="30%"
              className="icon icon-chevron-right-small reverse-icon"
              viewBox="0 0 5 8"
            >
              <path
                d="m.75 7 3-3-3-3"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
              ></path>
            </svg>
          ) : (
            <svg
              role="presentation"
              focusable="false"
              width="30%"
              height="30%"
              className="icon icon-chevron-left-small"
              viewBox="0 0 5 8"
            >
              <path
                d="M4.25 7L1.25 4l3-3"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
              ></path>
            </svg>
          )}
        </div>
        {/*  Carousel  */}

        <div className="h-fit" style={{ position: "relative" }}>
          <Carousel
            autoPlay
            interval={6000}
            showIndicators={false}
            showStatus={false}
            stopOnHover={false}
            infiniteLoop
            showThumbs={false}
            showArrows={false}
            renderArrowPrev={(onClickHandler) => (
              <div
                onClick={onClickHandler}
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "50%",
                  zIndex: 1,
                  opacity: 0,
                }}
              />
            )}
            renderArrowNext={(onClickHandler) => (
              <div
                onClick={onClickHandler}
                style={{
                  backgroundColor: "black",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "50%",
                  zIndex: 1,
                  opacity: 0,
                }}
              />
            )}
          >
            {images.map((img, idx) => {
              return (
                <div
                  key={idx}
                  className=" relative h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] bg-cover flex justify-center items-center bg-center  "
                >
                  <div>
                    <div
                      className="absolute top-0 left-0 bg-cover opacity-40 h-full w-full"
                      style={{ backgroundImage: `url(${img})` }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="absolute   top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-default     z-50 uppercase  w-fit space-y-5 text-center  h-f" onMouseEnter={() => {
              setcursorHide(true);
            }}
            onMouseLeave={()=>
            {
              setcursorHide(false)
            }
            }>
          <div>
            <div className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#dcfce7)] [-webkit-text-fill-color:transparent] inline-block text-[22px]  sm:text-2xl md:text-3xl lg:text-5xl xl:text-6xl text-center w-full tracking-tighter  ">
              Cargo dispatchers
            </div>
            <div className="font-bold font-[inherit]  inline-block text-green-100 text-[15px] sm:text-md md:text-xl lg:text-2xl xl:text-3xl text-center w-full tracking-tighter  ">
              Keep the wheels turning
            </div>
          </div>

          <button 
          onClick={()=>{
navigate("/services")
          }}
            className="more-about-us  hover:text-white px-[35px] md:px-10  md:py-4 py-2 rounded-full  mx-auto dark:bg-white bg-black z-40 font-semibold dark:text-black text-white  "
           
          >
            Discover More
          </button>
        </div>
      </div>
    </main>
  );
}
