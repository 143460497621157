import { useEffect } from "react";
import { Link } from "react-router-dom";

function AboutUs() {
  // const expertise = [];

  const logos = [
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/uqeqrw5ygnvx4nzymdju",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/uwr10eppwpc1whnwmvhx",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/hxlasdbajuhvrik5jtpx",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/rmwsnwiqupxnzefzat9l",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/bcc20x2sofracror3a7g",
    "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/uokzgg5p2dr5vuy9velm",
  ];

  const trucks = [
    {
      name: "Hotshot",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/lvrohp8hvdnjhsm38xjk",
      size: "ANY SIZE ANY TYPE",
    },
    {
      name: "Power Only",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/jizejrmhymbxr1xdmmnp",
      size: "48 & 53 FEET TRAILERS ONLY",
    },
    {
      name: "Step Deck",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/lqb4ammhezd3myowhvui",
      size: "53 feet trailers only",
    },
    {
      name: "Conestoga",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/oely6wye4ighia7e6zrd",
      size: "ANY SIZE ANY TYPE",
    },
    {
      name: "Box Truck",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/s5a1vukcjepbmn0su16b",
      size: "ANY SIZE ANY TYPE",
    },
    {
      name: "Dry Van",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/eyuhj3nrhzhdrjph2p5l",
      size: "53 feet trailers only",
    },
    {
      name: "Flatbed",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/ihbu0pvrjycuwxuf0zpt",
      size: "48 & 53 FEET TRAILERS ONLY",
    },
    {
      name: "Reefer",
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/ywt7o4plpfl9gmnladoh",
      size: "53 feet trailers only",
    },
  ];

  const reviews = [
    {
      name: "Jake T.",
      review:
        "Man, Cargo Commandos really knocked it outta the park for me! These guys are the real deal. My routes have never been this smooth, and the pay rates? Top-notch! They got my back 24/7, so I don’t have to worry about a thing. Keep up the solid work, y’all!",
      stars: 5,
    },
    {
      name: "Linda P.",
      review:
        "I’ve been rollin’ with Cargo Commandos for a hot minute now, and I gotta say, they’re on point. They hustle to get you the best loads and rates. Only reason it’s not a full 5 stars is ‘cause nothing’s perfect, but these guys come pretty dang close!",
      stars: 5,
    },
    {
      name: "Mike R.",
      review:
        "These folks at Cargo Commandos are the MVPs of dispatch! They ain’t just clockin’ in and out – they actually care about makin’ sure you’re set up right. They keep my wheels turnin’ and the cash flowin’. If you ain’t with them yet, you’re missin’ out!",
      stars: 5,
    },
    {
      name: "Sarah M.",
      review:
        "Cargo Commandos are straight-up lifesavers. I was swamped with paperwork and all sorts of nonsense before I signed on with them. Now, it’s smooth sailin’ – just me, the road, and my dispatcher makin’ sure I’m always in the money. Can’t thank them enough!",
      stars: 5,
    },
    {
      name: "Tony D.",
      review:
        "Cargo Commandos are the bomb! They got me covered from A to Z, whether it’s finding loads or dealing with brokers. They’re always just a call away, and that’s huge in this biz. Only knock is I wish I found ‘em sooner. Props to the whole team!",
      stars: 4,
    },
  ];

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="about-us-page-new">
      {/* Image Div */}
      <div
        className=" mt-20  h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] "
        style={{
          backgroundColor: "black",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className=" relative h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] bg-cover flex justify-center items-center bg-center  ">
          <div>
            <div
              className="absolute top-0 left-0 bg-cover opacity-40 h-full w-full"
              style={{
                backgroundImage: `url(https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/lidv9e7ygrxjdigp2i41)`,
              }}
            ></div>
          </div>
        </div>

        {/* Heading */}

        <div className="absolute   top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-default     z-50 uppercase  w-fit space-y-5 text-center  h-f">
          <div>
            <div className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#dcfce7)] [-webkit-text-fill-color:transparent] inline-block text-[32px]  sm:text-3xl md:text-4xl lg:text-6xl xl:text-8xl text-center w-full tracking-tighter  ">
              About Us
            </div>
            <div className="font-bold font-[inherit]  inline-block text-green-100 text-[15px] sm:text-md md:text-xl lg:text-2xl xl:text-3xl text-center w-full tracking-tighter  ">
              Cargo dispatchers
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pb-32">
        <div className="relative" id="home">
          <div
            aria-hidden="true"
            className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
          >
            <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
            <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
          </div>
          <div>
            <div className="relative pt-36 ml-auto p-3">
              <div className="lg:w-2/3 text-center mx-auto">
                <h1 className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl">
                  <span className="!bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#001adf)] [-webkit-text-fill-color:transparent] ">
                    {" "}
                    CARGO COMMANDOS{" "}
                  </span>
                  Elevating{" "}
                  <span className="pl-1 text-primary dark:text-white">
                    Your Trucking Success.
                  </span>
                </h1>
                <p className="mt-8 text-gray-700 dark:text-gray-300">
                  We are a premier truck dispatching company in the USA, serving
                  owner-operators and trucking companies across all sizes. With
                  a focus on dedicated dispatch services, our 80+ experienced
                  dispatchers, operating for over 7 years, ensure optimal routes
                  and top-paying freight loads weekly. We understand the
                  challenges of the road, so our team negotiates the highest
                  rates on your behalf, handling paperwork and broker
                  interactions for a seamless trucking experience. Our
                  commitment is to make the trucker’s life hassle-free. We
                  prioritize partnerships over clients, making your success
                  synonymous with ours. Maximize your earnings with our dispatch
                  services starting from just 5% of your weekly gross – no
                  contracts, anytime cancellation, and payment only after you
                  earn. Experience a complete dispatch solution with US-based,
                  certified dispatchers. Start maximizing your earning potential
                  today!
                </p>
                <div className="mt-16 flex flex-wrap justify-center gap-y-4 gap-x-6">
                  <Link
                    to="/carrier-setup"
                    className="relative flex w-[90%] items-center justify-center px-14 !py-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105  active:duration-75 active:before:scale-95 sm:w-max "
                  >
                    <span className="relative text-base font-semibold text-white dark:text-black">
                      Get started
                    </span>
                  </Link>
                </div>
                <div className="hidden py-8 mt-16 border-y border-gray-100 dark:border-gray-800 sm:flex justify-between">
                  <div className="text-left">
                    <h6 className="text-xl font-semibold text-gray-700 dark:text-white flex items-center gap-2">
                      <span className="text-4xl font-bold">150+ </span>{" "}
                      <span> Satisfied Carriers</span>
                    </h6>
                    <p className="mt-2 text-gray-500">We give profits</p>
                  </div>
                  <div className="text-left">
                    <h6 className="text-xl font-semibold text-gray-700 dark:text-white flex items-center gap-2">
                      <span className="text-4xl font-bold">80+ </span>{" "}
                      <span> Expert Dispatchers</span>
                    </h6>
                    <p className="mt-2 text-gray-500">Our experienced team</p>
                  </div>
                  <div className="text-left ">
                    <h6 className="text-xl font-semibold text-gray-700 dark:text-white flex items-center gap-2">
                      <span className="text-4xl font-bold">5+ </span>{" "}
                      <span> years of Experience</span>
                    </h6>
                    <p className="mt-2 text-gray-500">Honest to work</p>
                  </div>
                </div>
              </div>
              <div className="mt-12 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
                {logos.map((logo, idx) => {
                  return (
                    <div
                      key={idx}
                      className="p-4 grayscale transition duration-200 hover:grayscale-0"
                    >
                      <img
                        src={logo}
                        className="h-12 w-auto mx-auto"
                        loading="lazy"
                        alt="client logo"
                        width=""
                        height=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Trucks */}
      <section
        id="relative testimonies"
        className="py-40 bg-indigo-100 dark:bg-slate-900"
        style={{
          backgroundImage:
            "linear-gradient(180deg,#000000 , #2563EB , #000000 120%)",
        }}
      >
        <div className="max-w-6xl  md:mx-10 lg:mx-20 xl:mx-auto">
          <div className="transition mx-2 duration-500 ease-in-out transform scale-100 translate-x-0 translate-y-0 opacity-100">
            <div className="mb-12 space-y-5 md:mb-16 md:text-center">
              <h1 className="mb-5 text-indigo-100 text-3xl font-semibold  md:text-center md:text-5xl">
                {"OUR SPECIALIZATION"}
              </h1>
              <p className="text-xl text-indigo-100  md:text-center md:text-2xl">
                {"Diverse Expertise in Trucks & Trailers"}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 p-2 mdd:p-0 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
            {trucks.map((truck, idx) => {
              return (
                <div
                  key={idx}
                  className="relative  group w-[380px] max-w-[100%]  h-[350px] mx-auto"
                >
                  <div className="absolute transition rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 blur duration-400 group-hover:opacity-100 group-hover:duration-200"></div>

                  <div
                    className={`relative bg-[url(${truck.img})] bg-cover bg-center  leading-none rounded-xl overflow-hidden  ring-1 ring-gray-900/5  h-full w-full `}
                  >
                    <img
                      src={truck.img}
                      alt=""
                      className="h-full w-full object-cover"
                    />

                    {/* Overlay */}
                    <div
                      className="absolute  overlay top-0 bottom-0 left-0 h-full w-full opacity-90"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg, #02010108 0%, #000000 100%)",
                      }}
                    />

                    {/* Div.info */}
                    <div className="absolute  p-3 uppercase bottom-0 left-0">
                      <h2 className="text-xl  font-bold text-white">
                        {truck.name}
                      </h2>
                      <h2 className="text-yellow-400 uppercase text-sm">
                        {truck.size && truck.size}
                      </h2>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Reviews */}

      <section className=" px-4 py-12 md:py-24">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="font-black  text-center text-3xl leading-none uppercase max-w-2xl mx-auto mb-12">
            What Others Are Saying
          </h2>
          <div className="flex flex-col justify-center items-center   md:space-y-0 md:flex-row flex-wrap gap-3   relative">
            {reviews.map((review, index) => (
              <div
                key={index}
                className="w-[400px]  bg-gray-200 dark:bg-slate-800 max-w-[100%] rounded-lg p-8 text-center  "
              >
                <p className="font-bold uppercase">{review.name}</p>
                <p className="text-xl font-light italic text-gray-700 dark:text-gray-300">
                  {review.review}
                </p>
                <div className="flex items-center justify-center space-x-2 mt-4">
                  {[...Array(Math.floor(review.stars))].map((_, i) => (
                    <svg
                      key={i}
                      className="text-yellow-500 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
