import Lottie from "lottie-react";
import deliveryAnimation from "../../assets/lottie/delivery-truck.json";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTheme } from "../theme-provider";

function TruckOnRoad() {
  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollTrigger);

  const { theme } = useTheme();
  const isDark = theme === "dark";

  const animationOptions = {
    animationData: deliveryAnimation,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    style: {
      filter: isDark
        ? 'invert(1) hue-rotate(200deg) saturate(150%) brightness(75%)' // Adjust to achieve dark blue
        : 'invert(0) hue-rotate(0deg)'   // No filter for default color
    }
  };

  useGSAP(() => {
    gsap.to("#lottie-animation-truckk", {
      x: "100vw",
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: "#trigger-of-truckanimation",
        scroller: "body",
   
        start: window.innerWidth < 1280 ? "top 40%" : "top 80%",
        scrub: true,
      },
    });
  });

  return (
    <div id="trigger-of-truckanimation" className="relative h-[13vmax] min-h-fit w-full ">
      <Lottie
        id="lottie-animation-truckk"
        className="absolute bottom-0 h-[13vmax] left-[10] w-[13vmax] -mb-6 xl:-mb-8 2xl:-mb-[3rem] -ml-4"
        {...animationOptions}
      />
      <div className="absolute bottom-0 truck-under-line w-full h-[10px] bg-black dark:bg-blue-700 rounded-full"></div>
    </div>
  );
}

export default TruckOnRoad;
