function WhyUs() {
  return (
    <div id="aboutus" className="max-w-screen my-48 p-3">
      <div className="mobile-about-us md:hidden w-full ">
        <div className="image-container w-[80%] my-5 md:my-0  md:w-[60%] mx-auto ">
          <img
            src={
              "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/aekplhybu62xsjz2jpq6"
            }
            alt="About-Us"
            className="w-full rounded-2xl"
          />
        </div>

        <div className="about-us-content w-full space-y-5">
          <h4
            className="about-us-heading !bg-clip-text [-webkit-text-fill-color:transparent] text-center md:!text-[45px] "
            style={{
              background: `linear-gradient(89.21deg, #34B4FF , #34B4FF, #5200FF),
linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))`,

              fontSize: "35px",
              fontWeight: "900",
              lineHeight: "38.08px",
            }}
          >
            Why Choose Cargo Commandos?
          </h4>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              textAlign: "center",
            }}
            className="about-us-detials text-center md:!text-[20px] "
          >
            With over 5 years of experience, Cargo Commandos delivers tailored
            solutions for your specific truck type, optimizing earnings in
            high-value lanes. Our 24/7 dedicated support and exceptional
            customer service streamline your operations, minimizing paperwork
            and maximizing profitability. Partner with us today and experience
            the significant difference our dispatch services can make for your
            business.
          </p>
        </div>
      </div>

      {/* Desktop AboutUS */}
      <div className="md:flex hidden w-[98%] mdd:w-[95%] mx-auto items-center justify-between lg:w-[80%] ">
        {/* content */}
        <div className="about-us-data w-1/2 md:w-[50%] text-start">
          <h4
            className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#34B4FF,#5200FF)] [-webkit-text-fill-color:transparent] inline-block text-[35px] md:text-6xl  w-full"
            style={{
              fontSize: "45px",
              fontWeight: "900",
              lineHeight: "59.08px",
            }}
          >
            Why Choose Cargo Commandos?
          </h4>
          <div className="w-[70%] h-[3px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#34B4FF]  my-10 rounded-[100%] opacity-50 " />
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "30px",
              textAlign: "left",
            }}
            className="!font-sans"
          >
            With over 5 years of experience, Cargo Commandos delivers tailored
            solutions for your specific truck type, optimizing earnings in
            high-value lanes. Our 24/7 dedicated support and exceptional
            customer service streamline your operations, minimizing paperwork
            and maximizing profitability. Partner with us today and experience
            the significant difference our dispatch services can make for your
            business.
          </p>

          {/* Learn More button  */}
        </div>
        {/* image */}
        <div
          className="image-container w-[50%] rounded-lg md:w-[40%]
        "
        >
          <img
            src={
              "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/aekplhybu62xsjz2jpq6"
            }
            alt="Why-us"
            className="w-full rounded-2xl"
          />
        </div>
      </div>
      {/* <div className="w-[80vw] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#FF2DF7] mx-auto my-20 rounded-[100%] opacity-70 " /> */}
    </div>
  );
}

export default WhyUs;
