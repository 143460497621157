"use client";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from "@/components/theme-provider";

function Services() {
  const [windowWidth, setWindowWidth] = useState(0);

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(useGSAP);

  const services = [
    {
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/qzb20rj3wq33bpdxfc6f",
      name: "FIND LOAD",
      title: "FINDING LOADS MADE EASY",
      description:
        "Let us handle your hauling preferences! Share your desired earnings, travel distance, and preferred routes – whether local or interstate. As your dedicated dispatcher, we sift through numerous high-paying loads, ensuring you’re booked with at least two loads ahead. We offer flexible payment options, including factoring and quick pay, tailored to your preference. Remember, when the wheels are in motion, your earnings keep flowing. Keep on trucking hassle-free with us!",
     
    },
    {
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/naxr2hhiumlaqdqz8z8h",
      name: "OBTIMISE SUCCESS",
      title: "Optimizing Success Through Personalized Service",
      description:
        "Our commitment to success centers around cultivating strong client relationships. By assigning a dedicated personal dispatcher to you, our emphasis lies in fostering a personal connection. This approach establishes a foundation built on consistency, efficiency, and loyalty, ensuring a mutually prosperous relationship. We take the time to understand your routes, preferred home times, and availability. It’s a partnership geared for success, and we invite you to embrace the same win-win spirit. Together, let’s pave the way for efficiency and excellence in every journey.",
      
    },
    {
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/zpxxtptwnv8dy6smrpfl",
      name: "BILLING AND COLLECTION",
      title: "Effortless Billing and Collection",
      description:
        "Upon load delivery, simply email or fax us your paperwork (BOL, invoicing, factoring info) at your convenience. We take care of forwarding all necessary documents to the broker/shipper, saving you time and hassle. Our commitment extends to ensuring prompt payment according to the load agreement through diligent follow-ups. If you prefer managing your own billing and paperwork, no problem – we are here to assist and cater to your preferences. Your convenience is our priority!",
      
    },
    {
      img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/m40znjrftgyll8n6qolh",
      name: "EASY SOLUTIONS",
      title: "Streamlined Factoring and Invoicing Solutions",
      description:
        "Truckers often encounter the challenge of delayed payments from shippers or brokers, with funds arriving 30 to 60 days after load delivery. Our solution? Factoring. Our dedicated teams ensure truckers receive their money promptly, handling all the necessary paperwork and requirements with factoring companies. This means truckers can focus solely on deciding where to allocate their hard-earned money, without the stress of financial delays. Drive worry-free with our efficient factoring and invoicing support.",
    
    },
    {
        img: "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/sngamal8miwkfsglwduc",
        name: "EFFICIENT RESPONSE",
        title: "Efficient Driver Response Unit",
        description:
          "Ensuring seamless delivery operations on the road is crucial. Our trained dispatchers proactively monitor trucks, eliminating the need for drivers to be concerned about minor issues during their journeys. With our 24/7 Driver Response Unit, any challenges or concerns drivers encounter on the road are promptly addressed. We prioritize the smooth execution of your job, allowing drivers to focus solely on their core responsibilities without the distraction of road-related issues.",
        
      },
  ];

  // useGSAP(() => {
  //   const cards = document.querySelectorAll(".service-card");

  //   cards.forEach((card, index) => {
  //     gsap.from(card, {
  //       y: "30%",
  //       opacity: 0,
  //       stagger: 0.4,
  //       scrollTrigger: {
  //         trigger:card,

  //         start:"top 60%",
  //         end: "+=90",

  //         scrub: 1,

  //       },
  //     });
  //   });

  // });
  useEffect(() => {
    // Update the state with the window width
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Set initial window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Setup ScrollTrigger animations
    const cards = document.querySelectorAll(".service-card");

    cards.forEach((card) => {
      gsap.from(card, {
        y: "50",
        opacity: 0,
        scale: 0.7,
        scrollTrigger: {
          trigger: card,
          start: "top 60%",
          end: "+=90",
          scrub:true,
         
        
        },
      });
    });
  }, [windowWidth]); // Re-run on windowWidth change


const {theme} = useTheme();
  const isDark = theme === "dark";
  useEffect(()=>{
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    
  },[])

  return (
    <div id="services" className="relative  ">
      {/* Image Div */}
      <div
        className=" mt-20  h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] "
        style={{
          backgroundColor: "black",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className=" relative h-[40vh]  sm:h-[40vh] md:h-[50vh] lg:h-[60vh] xl:h-[80vh] bg-cover flex justify-center items-center bg-center  ">
          <div>
            <div
              className="absolute top-0 left-0 bg-cover opacity-40 h-full w-full"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/dywwro5funi5y1m1trlf")`,
              }}
            ></div>
          </div>
        </div>

        {/* Heading */}

        <div className="absolute   top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-default     z-50 uppercase  w-fit space-y-5 text-center  h-f">
          <div>
            <div className="font-bold font-[inherit] !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#34B4FF,#dcfce7)] [-webkit-text-fill-color:transparent] inline-block text-[32px]  sm:text-3xl md:text-4xl lg:text-6xl xl:text-8xl text-center w-full tracking-tighter  ">
              Services
            </div>
            <div className="font-bold font-[inherit]  inline-block text-green-100 text-[15px] sm:text-md md:text-xl lg:text-2xl xl:text-3xl text-center w-full tracking-tighter  ">
              Cargo dispatchers
            </div>
          </div>
        </div>
      </div>

      {/* Services */}
      <div className="services-container my-[10vh] pb-24">
        <h1
          style={{
            background: `linear-gradient(to right, ${isDark ? "#000000":"#ffffff"}, #2563EB , ${isDark ? "#000000":"#ffffff"})`,
          }}
          className="relative text-3xl h-[65px] md:h-[106px] lg:h-[146px] flex items-center justify-center pl-5 font-semibold md:font-extrabold mt-8 mb-14 bg-red-400 w-[85%] mx-auto md:text-4xl text-white rounded-2xl md:w-[60%] lg:w-[80%]"
        >
          OUR SERVICES
        </h1>

        {/* Service multiple cards */}

        {windowWidth !== 0 ? (
          <div className="services-cards space-y-20 lg:space-y-26 w-[95%] xl:w-[80%] max-w-[1600px] mx-auto h-full">
            {services.map((s, idx) => {
              return (
                <div key={idx}>
                  {/* Service Card */}

                  <>
                    {windowWidth > 768 ? (
                      <>
                        {idx % 2 === 0 ? (
                          <>
                            <div className="service-card md:flex items-center justify-center min-h-fit  ">
                              {/* image */}
                              <div className="service-image rounded-xl overflow-hidden w-[96%] sm:w-[60%] md:w-[40%] sm:h-full md:h-full mx-auto bg-red-600  ">
                                <img
                                  src={s.img}
                                  alt="service"
                                  className="w-full h-full"
                                />
                              </div>
                              {/* content */}
                              <div className="service-content flex flex-col items-center md:items-start md:justify-center md:pl-12 md:text-start py-7 md:w-[60%] space-y-1 ">
                                <h2
                                  style={{
                                    fontFamily: " sans-serif",
                                    fontWeight: 900,
                                    lineHeight: "21.94px",
                                    letterSpacing: "0.16em",
                                  }}
                                  className="service-title text-center !bg-clip-text bg-gradient-to-r from-[#2563EB] via-[#A816FB] to-[#5200FF] [-webkit-text-fill-color:transparent]  text-[18px] sm:!text-2xl"
                                >
                                  {s.name}
                                </h2>
                                <h2 className="service-title leading-7 text-4xl md:text-5xl font-bold text-center    md:text-start xl:w-[100%]">
                                  {s.title}
                                </h2>
                                <p className="service-description w-[80%] md:w-full mx-auto lg:mx-0 md:text-lg  text-center md:text-start xl:w-[80%] ">
                                  {s.description}
                                </p>

                                <div className="w-[60%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF] to-[#2563EB]  rounded-[100%] opacity-70 !my-3"></div>
                                <Link
                                  style={{
                                    background:
                                      "linear-gradient(to right, #2563EB, #A816FB ,#5200FF)",
                                  }}
                                  to={"/contactus"}
                                  className="px-9 py-3 text-white font-bold rounded-full  "
                                >
                                  Learn More
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="service-card md:flex">
                              {/* content */}
                              <div className="service-content flex flex-col items-center md:items-start md:justify-center md:pr-12 md:text-start py-7 md:w-[60%] space-y-4">
                                <h2
                                  style={{
                                    fontFamily: "Montserrat, sans-serif",
                                    fontWeight: 900,
                                    lineHeight: "21.94px",
                                    letterSpacing: "0.16em",
                                  }}
                                  className="service-title text-center !bg-clip-text bg-gradient-to-r from-[#2563EB] via-[#A816FB] to-[#5200FF] [-webkit-text-fill-color:transparent]  text-[18px] sm:!text-2xl"
                                >
                                  {s.name}
                                </h2>
                                <h2 className="service-title leading-7 text-4xl md:text-5xl font-bold text-center md:text-start  xl:w-[85%]">
                                  {s.title}
                                </h2>
                                <p className="service-description w-[80%] md:w-full mx-auto lg:mx-0 md:text-lg text-center md:text-start xl:w-[80%]">
                                  {s.description}
                                </p>
                                <div className="w-[60%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF] to-[#2563EB] my-20 rounded-[100%] opacity-70"></div>
                                <Link
                                to={"/contactus"}
                                  style={{
                                    background:
                                      "linear-gradient(to right, #2563EB, #A816FB ,#5200FF)",
                                  }}
                                  
                                  className="px-9 py-3 text-white font-bold rounded-full  "
                                >
                                  Learn More
                                </Link>
                              </div>
                              {/* image */}
                              <div
                                className="service-image bg-black rounded-xl overflow-hidden w-[96%] sm:w-[60%] sm:h-[300px] md:h-full md:w-[40%] mx-auto"
                                style={{
                                  boxShadow:
                                    " rgba(0, 0, 0, 0.5) -7px 10px 15px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
                                }}
                              >
                                <img
                                  src={s.img}
                                  alt="service"
                                  className="w-full h-full"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="service-card mdd:flex w-full ">
                          <h2
                            style={{
                              fontFamily: "Montserrat, sans-serif",
                              fontWeight: 700,
                              lineHeight: "21.94px",
                              letterSpacing: "0.16em",
                            }}
                            className="service-title text-center !bg-clip-text [background:linear-gradient(rgba(0,0,_0,_0),_rgba(0,_0,_0,_0)),_linear-gradient(#2563EB,#A816FB,#5200FF)]  [-webkit-text-fill-color:transparent] my-5 text-[18px] sm:!text-2xl"
                          >
                            {s.name}
                          </h2>
                          {/* image */}

                          <div
                            className="service-image rounded-xl overflow-hidden w-[96%] sm:w-[80%] min-h-[200px] mx-auto"
                            style={{
                              boxShadow:
                                " rgba(0, 0, 0, 0.5) 0px 3px 15px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
                            }}
                          >
                            <img
                              src={s.img}
                              alt="service"
                              className="w-full h-full"
                            />
                          </div>
                          {/* content */}
                          <div className="service-content flex flex-col items-center py-7 space-y-4">
                            <p
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                fontSize: "20px",
                                fontWeight: 700,
                                lineHeight: "24.38px",
                                textAlign: "center",
                              }}
                              className="w-[60%] mx-auto sm:!text-[25px] "
                            >
                              {s.title}
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat, sans-serif",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "19.5px",
                                textAlign: "center",
                              }}
                              className="service-description w-[100%] sm:w-[90%] sm:!text-[19px]"
                            >
                              {s.description}
                            </p>
                            {/* <Link
                          style={{
                            background:
                              "linear-gradient(to right, #2563EB, #A816FB ,#5200FF)",
                          }}
                          href={`/services/${s.name}`}
                          className="w-[60%] mx-auto py-4 rounded-full font-bold text-xl !mt-8 text-center"
                        >
                          Learn More
                        </Link> */}
                          </div>
                          <div className="w-[60%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF] to-[#2563EB]  rounded-[100%] opacity-70 !mb-5 mx-auto"></div>
                          <div className="button-learn-mob  flex justify-center">
                            <Link
                              style={{
                                background:
                                  "linear-gradient(to right, #2563EB, #A816FB ,#5200FF)",
                              }}
                              to={"/contactus"}
                              className="px-12 py-3 text-white font-bold rounded-full mx-auto   "
                            >
                              Learn More
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <p className=" h-screen w-screen flex items-center justify-center">
              LOADING.......
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default Services;
