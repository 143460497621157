import { useNavigate } from "react-router-dom";

function PricingPage() {

const navigate = useNavigate();
  const pricingPlans = [
    {
      title: "Silver",
      monthlyPrice: "$250",
      percentage: "4",
     
      description:
        "The Silver Plan is perfect for those who need reliable dispatching during business hours. With a dedicated personal dispatcher and top-notch load negotiation, you can focus on driving while we handle the logistics. Our team ensures you get the best pay rates and quick pay assistance, making your business more efficient and profitable.",
      features: [
        "Dispatching within Business Hours",
        "Finding & Assigning Loads",
        "Dedicated Personal Dispatcher",
        "Detention & Lumper Fee",
        "Negotiating Best Pay Rates",
        "Negotiating Quick Pay Rates",
        "Quick Pay Assistance",
        "Carrier Packets",
      ],
      unavailableFeatures: [
        "24/7 Dispatch",
        "Factoring Invoices",
        "The Credit Score Of The Broker",
        "Driver Assistance",
        "Owner Operator Assistance",
        "Custom Clearance US & Canada",
        "Box Truck","Hotshot", "Access to premium lanes",
        "Routes Optimization",
        "TONU",
        "Run in pre booking"
      ],
    },
    {
      title: "Recommended",
      monthlyPrice: "$300",
   
      percentage: "6",
      description:
        "The Recommended Plan offers 24/7 dispatching with a dedicated dispatcher, ensuring you’re always covered. It includes full support for load management, pay negotiations, and factoring invoices, making it ideal for drivers who need constant, reliable service.",
      features: [
        "24/7 Dispatch",
        "Finding & Assigning Loads",
        "Dedicated Personal Dispatcher",
        "Detention & Lumper Fee",
        "TONU",
        "Dispatching within Business Hours",
        "Negotiating Best Pay Rates",
        "Negotiating Quick Pay Rates",
        "Quick Pay Assistance",
        "Carrier Packets",
        "Factoring Invoices",
        "The Credit Score Of The Broker",
        "Driver Assistance",
        "Owner Operator Assistance",
        
      ],
      unavailableFeatures: ["Custom Clearance US & Canada","Box Truck","Hotshot", "Access to premium lanes",
        "Routes Optimization",
        
        "Run in pre booking"],
    },
    {
      title: "Premium " ,
      subtitle:"(Box Trucks , Hotshots)",
      monthlyPrice: "$400",
      
      percentage: "10",
      description:
        "The Premium Plan delivers top-tier, 24/7 dispatching with specialized Box Truck and Hotshot support, plus cross-border clearance. It’s the ultimate package for businesses seeking unmatched service and complete logistical mastery.",
      features: [
        "24/7 Dispatch",
     
        "Finding & Assigning Loads",
        "Dedicated Personal Dispatcher",
        "Detention & Lumper Fee",
        "TONU",
        "Negotiating Best Pay Rates",
        "Negotiating Quick Pay Rates",
        "Quick Pay Assistance",
        "Carrier Packets",
        "Factoring Invoices",
        "The Credit Score Of The Broker",
        "Driver Assistance",
        "Custom Clearance US & Canada",
        "Box Truck",
        "Hotshot",
        "Access to premium lanes",
        "Routes Optimization",
        "Run in pre booking",
        

      ],
      unavailableFeatures: [],
    },
    // Add more plans as needed
  ];

  return (
    <section
      id="pricing"
      className="relative flex flex-col overflow-hidden  items-center justify-center  h-fit  bg-contain bg-center bg-no-repeat py-20 "
      style={{
        backgroundImage:
          "linear-gradient(180deg,#000000 , #2563EB , #000000 120%)",
      }}
    >
      {/* <img
        className="absolute w-full h-full lg:w-auto lg:h-auto "
        src="https://tutorials.lexingtonthemes.com/images/blueBlob.svg"
        alt="Background"
      /> */}

      <h1 className="text-4xl font-bold uppercase text-white ">
        {" "}
        dispatch fees
      </h1>

      <div className="relative items-center px-8  mx-auto md:px-12 max-w-7xl">
        
        <div className="grid grid-cols-1 gap-8 mt-12  lg:grid-cols-3">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="flex flex-col justify-between p-8 shadow-2xl rounded-3xl bg-black/20  shadow-gray-900/50"
            >
              <div>
                <div className="flex flex-col justify-between gap-3">
                  <p className="text-2xl uppercase mdd:text-3xl font-bold  tracking-tight text-white">
                    {plan.title}
                  </p>
                  <p>
                   {plan.subtitle && "For " +plan.subtitle }

                  </p>
                  <p className="text-white">
                    <span className="text-2xl tracking-tight font-bold">
                      { plan.monthlyPrice}
                    </span>
                    <span className="text-base font-medium">
                      / per week
                     
                    </span>
                    
                  </p>
                  <p className="text-yellow-200">
                    <span className="text-xl  tracking-tight font-semibold">
                      {plan.percentage} {"%  "}
                    </span>
                    <span className="text-base uppercase font-medium">
                      per load
                     
                    </span>
                  </p>
                </div>
                <p className="mt-8 text-xs text-white/80">{plan.description}</p>
                <ul className="flex flex-col order-last mt-8 text-sm text-white gap-y-3">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="inline-flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M9 12l2 2l4 -4" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                  {plan.unavailableFeatures.map((feature, i) => (
                    <li
                      key={i}
                      className="inline-flex items-center gap-2 opacity-50"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-x"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M10 10l4 4m0 -4l-4 4" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex mt-6">
                <button
                    onClick={() => {
                      navigate("/carrier-setup");
          
                     
                      
                    }}
                  title={plan.title}
                  aria-label={`get started with ${plan.title}`}
                  className="flex items-center justify-center w-full h-12 px-4 py-2 text-base font-medium transition-all duration-200 rounded-xl text-white bg-black/50 hover:bg-blue-700"
                >
                  Get started
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PricingPage;
