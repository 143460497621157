import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/layouts/Footer/Footer";
import NavBar from "./components/layouts/NavBar/NavBar";
import { ThemeProvider } from "./components/theme-provider";
import { MeContextProvider } from "./context/meContext";
import AboutUs from "./pages/aboutus/AboutUs";
import AdminDashboard from "./pages/admin/dashboard/AdminDashboard";
import DashboardHome from "./pages/admin/dashboard/DashboardHome";
import CarrierSetup from "./pages/carrierSetup/CarrierSetup";
import ContactUs from "./pages/contact-us/ContactUs";
import Home from "./pages/home/Home";
import LoginPage from "./pages/login/LoginPage";
import Services from "./pages/services/Services";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from "./ProtectedRoute";
import AdminUsers from "./pages/admin/users/AdminUsers";
import AllUsers from "./pages/admin/users/AllUsers";
import AddUser from "./pages/admin/users/AddUser";
import { ToastContainer } from "react-toastify";
import Adminmessages from "./pages/admin/messages/Adminmessages";
import AdminCarrierMessages from "./pages/admin/carrierMessages/AdminCarrierMessages";
import PrivacyPolicies from "./pages/privacyPolicies/PrivacyPolicies";
import TermsConditions from "./pages/termsConditions/TermsConditions";
function App() {
  const [me, setMe] = useState([]);
  const [meLoading, setmeLoading] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);

  useEffect(() => {
    fetchMe();
  }, [isAuthenticated]); // Empty dependency array to run only once

  const fetchMe = async () => {
    try {
      setmeLoading(true);
      const url = `/api/v1/me`;

      // Perform the request using axios
      const response = await fetch(url);

      const data = await response.json();
      console.log(data.success);

      if (data.success) {
        setMe(data.admin);
        setisAuthenticated(true);
      } else {
        setisAuthenticated(false);
      }
    } catch (error) {
      console.log(error);
      setisAuthenticated(false);
    } finally {
      setmeLoading(false);
    }
  };

  return (
    <MeContextProvider
      value={{
        me: me,

        isAuthenticated,
        loading: meLoading,

        fetchMe,
      }}
    >
      <div className="max-w-screen overflow-hidden">
        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
          <BrowserRouter>
            <NavBar />

            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/carrier-setup" element={<CarrierSetup />} />
              <Route path="/admin/login" element={<LoginPage />} />
              <Route path="/privacy-policies" element={<PrivacyPolicies />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />


              {/* Admin Dashbaord Routes */}
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    loading={meLoading}
                  >
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<DashboardHome />} />
                {/* SubRoute */}
                <Route
                  path="users"
                  element={
                    <ProtectedRoute
                      isAuthenticated={isAuthenticated}
                      loading={meLoading}
                    >
                      <AdminUsers />
                    </ProtectedRoute>
                  }
                >
                  <Route path="" element={<AllUsers />} />
                  <Route path="upload" element={<AddUser />} />
                </Route>
                <Route
                    path="messages"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        loading={meLoading}
                      >
                        <Adminmessages />
                      </ProtectedRoute>
                    }
                  />
                   <Route
                    path="carriermessages"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        loading={meLoading}
                      >
                        <AdminCarrierMessages />
                      </ProtectedRoute>
                    }
                  />
              </Route>
            </Routes>
            <ToastContainer />
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </MeContextProvider>
  );
}

export default App;
