import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import "./number.css"; // Keep your custom styles if any
gsap.registerPlugin(ScrollTrigger);

function NumbersDetailPage() {
  const numbersRef = useRef(null);

  useEffect(() => {
    const elements = gsap.utils.toArray(numbersRef.current.querySelectorAll('.number'));

    elements.forEach((element) => {
      gsap.fromTo(
        element,
        { innerText: 0 },
        {
          innerText: element.dataset.value,
          duration: 2,
          ease: 'power1.out',
          onUpdate: function () {
            const value = Math.ceil(this.targets()[0].innerText);
            this.targets()[0].innerText = value;
          },
          scrollTrigger: {
            trigger: element,
            start: 'top 80%',
            end: 'top 50%',

            
          },
        }
      );
    });
  }, []);

  return (
    <div ref={numbersRef} className="number-details p-5 py-16 mt-[10vh]">
      <div className="flex-col flex space-y-5 w-[90%] sm:w-[70%] mx-auto mdd:flex-row mdd:space-y-0 mdd:space-x-4 lg:w-[60%] lg:space-x-12">
        <div className="w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg shadow-black ">
          <div className="text-4xl w-fit mx-auto font-bold text-white">
            $<span className="number" data-value="20000">0</span>+
          </div>
          <div className="text-lg w-fit mx-auto font-semibold text-white">Load Booked</div>
        </div>
        <div className="w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg shadow-black ">
          <div className="text-4xl w-fit mx-auto font-bold text-white">
            $<span className="number" data-value="8000">0</span>+
          </div>
          <div className="text-lg w-fit mx-auto font-semibold text-white">
            Weekly Gross Revenue
          </div>
        </div>
        <div className="w-full rounded-2xl bg-blue-600 py-6 space-y-2 shadow-lg shadow-black ">
          <div className="text-4xl w-fit mx-auto font-bold text-white">
            $<span className="number" data-value="3">0</span>+
          </div>
          <div className="text-lg w-fit mx-auto font-semibold text-white">
            Per Mile Average
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumbersDetailPage;
