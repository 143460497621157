import { useEffect } from "react"

function PrivacyPolicies() {

  
  useEffect(()=>{

    window.scroll({
      top:0,
      behavior:"smooth"
    })

  },[])

  return (
    <div className="mt-24 w-full p-4 md:w-[80%] mx-auto ">
      <div className="px-6 py-4">
  <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
  <p className="mb-4">
    At Cargo Commandos (the “Company” or “We”), we respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit this website (our “Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information. 
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Applicability</h2>
  <p className="mb-4">
    This policy applies to information we collect:
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>On this Website.</li>
    <li>In e-mail, text, and other electronic messages between you and this Website.</li>
    <li>Through mobile and desktop applications you download from this Website.</li>
    <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.</li>
  </ul>

  <p className="mb-4">
    It does not apply to information collected by:
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>Us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries).</li>
    <li>Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.</li>
  </ul>

  <h2 className="text-xl font-semibold mt-6 mb-2">Children Under the Age of 13</h2>
  <p className="mb-4">
    Our Website is not intended for children under 13 years of age. No one under age 13 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Information We Collect</h2>
  <p className="mb-4">
    We collect several types of information from and about users of our Website, including:
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>Personal information (e.g., name, postal address, e-mail address, telephone number).</li>
    <li>Non-personal information that does not identify you individually.</li>
    <li>Information about your internet connection, the equipment you use, and usage details.</li>
  </ul>

  <p className="mb-4">
    We collect this information directly from you, automatically through your navigation, or from third parties (e.g., business partners).
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Automatic Data Collection Technologies</h2>
  <p className="mb-4">
    As you navigate through and interact with our Website, we may use automatic data collection technologies to collect information, including:
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>Details of your visits to our Website (traffic data, location data, etc.).</li>
    <li>Information about your computer and internet connection (IP address, operating system, browser type).</li>
  </ul>

  <p className="mb-4">
    We use cookies, Flash cookies, and web beacons to collect this information. These technologies help us improve our Website and provide better, personalized services.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Third-party Use of Cookies and Tracking Technologies</h2>
  <p className="mb-4">
    Some content or applications on the Website, including advertisements, are served by third parties. These third parties may use cookies and tracking technologies to collect information about you and your online activities over time and across different websites and services.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">How We Use Your Information</h2>
  <p className="mb-4">We use your information to:</p>
  <ul className="list-disc list-inside mb-4">
    <li>Present our Website and its contents to you.</li>
    <li>Provide you with information, products, or services that you request from us.</li>
    <li>Notify you about changes to our Website or services.</li>
    <li>Allow you to participate in interactive features.</li>
    <li>Fulfill any purpose for which you provide the information.</li>
  </ul>

  <h2 className="text-xl font-semibold mt-6 mb-2">Disclosure of Your Information</h2>
  <p className="mb-4">
    Cargo Commandos LLC reserves the right to maintain customer confidentiality and does not sell, share, or rent customer information to third parties, except as described in this policy.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Accessing and Correcting Your Information</h2>
  <p className="mb-4">
    You may send us an email via our contact link to request access to, correct, or delete any personal information that you have provided to us.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Your California Privacy Rights</h2>
  <p className="mb-4">
    California residents may request certain information regarding our disclosure of personal information to third parties for direct marketing purposes under California Civil Code Section § 1798.83.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Data Security</h2>
  <p className="mb-4">
    We implement measures to secure your personal information. However, transmission over the internet is not completely secure, and any transmission is at your own risk.
  </p>

  <h2 className="text-xl font-semibold mt-6 mb-2">Changes to Our Privacy Policy</h2>
  <p className="mb-4">
    We may make changes to our privacy policy and will notify you by email or through a notice on our Website. The date of the last revision will be displayed at the top of the page.
  </p>
</div>

    </div>
  )
}

export default PrivacyPolicies