import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const services = [
    "Finding Loads",
    "Assigning Loads",
    "Billing paperwork & Collection",
    "Factoring & Invoicing",
    "Quick Pay Assistance",
    "24/7 Dispatch",
  ];

  return (
    <div>
      <div className="relative flex justify-start md:justify-center md:items-end py-11">
        <img
          className="absolute dark:hidden object-cover top-10 h-full w-full xl:mt-10 z-0 "
          src="https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/czcddwgehbkv14ma4npg"
          alt="background"
        />
        <div className="flex pt-36 md:pt-32 lg:pt-40 xl:pt-96   px-4 md:px-6  xl:px-20 flex-col justify-start items-start md:justify-center md:items-center relative z-10">
          <div className="flex  flex-col items-start justify-start xl:justify-center xl:space-x-8 xl:flex-row">
            <div className="flex justify-start items-center space-x-4">
              <div className="cursor-pointer w-12">
                <img
                  src="https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/prel1hyeef7ua2ugzq1y"
                  alt="logo"
                />
              </div>
              <p className="w-60 text-xl xl:text-2xl font-semibold leading-normal text-white uppercase">
                Cargo Commandos
              </p>
            </div>
            <div className="mt-12 xl:mt-0 grid grid-cols-1 sm:grid-cols-3 gap-y-12 sm:gap-y-0 w-full md:w-auto sm:gap-x-20 md:gap-x-28 xl:gap-8">
              <div className="sm:w-40 md:w-auto xl:w-72 flex justify-start items-start flex-col space-y-6">
                <h2 className="text-base xl:text-xl font-bold xl:font-semibold border-b-2 border-white leading-4 xl:leading-5 text-white">
                  LINKS
                </h2>
                <button
                  onClick={() => {
                    navigate("/");
                    setTimeout(() => {
                      window.scroll({
                        top: 0,
                        behavior: "smooth",
                      });
                    }, 200);
                  }}
                  className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                >
                  Home
                </button>
                <button
                  onClick={() => {
                    navigate("about-us");
                  }}
                  className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                >
                  About Us
                </button>
                <button
                  onClick={() => {
                    navigate("services");
                  }}
                  className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                >
                  Services
                </button>
                <button
                  onClick={() => {
                    navigate("carrier-setup");
                  }}
                  className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                >
                  Carrier Setup
                </button>
                <button
                  onClick={() => {
                    navigate("/");
                    setTimeout(() => {
                      const servicesElement =
                        document.getElementById("pricing");
                      if (servicesElement) {
                        servicesElement.scrollIntoView({ behavior: "smooth" });
                      }
                    }, 200);
                  }}
                  className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                >
                  Pricing
                </button>
              </div>

              <div className="sm:w-40 md:w-auto xl:w-72 flex justify-start items-start flex-col space-y-6">
                <h2 className="text-base xl:text-xl font-bold xl:font-semibold leading-4 xl:leading-5 text-white border-b-2 border-white">
                  Services
                </h2>

                {services.map((service) => {
                  return (
                    <button
                      onClick={() => {
                        navigate("services");
                      }}
                      key={service}
                      className="text-left text-base hover:text-gray-400 leading-none text-gray-100"
                    >
                      {service}
                    </button>
                  );
                })}
              </div>

              <div className=" xl:w-72 flex justify-start items-start flex-col space-y-6">
                <h2 className="text-base xl:text-xl font-bold xl:font-semibold leading-4 xl:leading-5 text-white border-b-2 border-white">
                  Contact Info
                </h2>
                <a
                  href="https://wa.me/13023295394"
                  target="_blank" 
                  className="text-base text-left hover:text-gray-400 leading-none text-gray-100"
                >
                  Tell : +1 (302) 329-5394
                </a>
                <a
                  href="mailto:cargocommandos@gmail.com" 
                  target="_blank" 
                  className="text-base text-left hover:text-gray-400 leading-none text-gray-100"
                >
                  Email: cargocommandos@gmail.com
                </a>
                <a href="https://maps.app.goo.gl/HBqYfqspG7RLxmWJ9" target="_blank" className="text-base text-left hover:text-gray-400 leading-none text-gray-100">
                  Visit Us:  500 Marquette Ave NW, Suite 1200 Albuquerque, NM
                  87102 United States
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 py-8 px-8 flex  xl:justify-between xl:flex-row flex-col-reverse items-center xl:items-start w-full ">
            <p className="mt-10 md:mt-12 xl:mt-0 text-sm leading-none text-white">
              &copy; CARGO COMMANDOS DISPATCHERS All Rights Reserved.
            </p>
            <div className="mt-10 md:mt-12 xl:mt-0 md:flex-row flex-col flex md:justify-center w-full md:w-auto justify-start items-start space-y-4 md:space-y-0 md:items-center md:space-x-4 xl:space-x-6">
              <Link to={"/terms-conditions"} className="text-base leading-none text-white hover:text-gray-300">
                Terms of service
              </Link>
              <Link to={"/privacy-policies"}  className="text-base leading-none text-white hover:text-gray-300">
                Privacy Policy
              </Link>
            </div>
            <div className="flex  justify-start md:justify-end items-start  w-full md:w-auto md:items-center space-x-6 ">
              <button className="text-white hover:text-gray-200 w-6">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.25 5.13282C22.406 5.49955 21.513 5.74116 20.5992 5.85001C21.5595 5.28769 22.2817 4.39434 22.6303 3.33751C21.7224 3.86841 20.7307 4.24092 19.6978 4.43907C19.2629 3.98322 18.7397 3.62059 18.1603 3.3732C17.5808 3.12581 16.9571 2.99884 16.327 3.00001C13.7761 3.00001 11.7117 5.03438 11.7117 7.5422C11.7099 7.89102 11.7499 8.23881 11.8308 8.57813C10.0016 8.49238 8.2104 8.02575 6.57187 7.2081C4.93333 6.39044 3.48351 5.23977 2.31516 3.8297C1.90527 4.52069 1.6885 5.30909 1.6875 6.11251C1.6875 7.68751 2.50922 9.0797 3.75 9.89532C3.01487 9.87787 2.29481 9.68331 1.65094 9.32813V9.38438C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9433 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3333 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07188C20.9616 7.87501 20.9564 7.67813 20.947 7.48595C21.8485 6.84472 22.6283 6.04787 23.25 5.13282V5.13282Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button className="text-white hover:text-gray-200 w-6">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.5 12.0645C22.5 6.26602 17.7984 1.56445 12 1.56445C6.20156 1.56445 1.5 6.26602 1.5 12.0645C1.5 17.3051 5.33906 21.649 10.3594 22.4374V15.1005H7.69266V12.0645H10.3594V9.75117C10.3594 7.12008 11.9273 5.66555 14.3255 5.66555C15.4744 5.66555 16.6763 5.87086 16.6763 5.87086V8.45508H15.3516C14.048 8.45508 13.6402 9.26414 13.6402 10.0957V12.0645H16.552L16.087 15.1005H13.6406V22.4384C18.6609 21.6504 22.5 17.3065 22.5 12.0645Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
