import { ModeToggle } from "@/components/mode-toggle";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useME from "../../../context/meContext";
import "./navbar.css";
import { useTheme } from "@/components/theme-provider";
function NavBar() {
  const {theme }= useTheme();
  const { isAuthenticated, fetchMe } = useME();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isNavBarOpen, setNavBarOpen] = useState(false);
  // const { theme } = useTheme();
  const handleMenuBtn = () => {
    setNavBarOpen((prevIsNavBarOpen) => !prevIsNavBarOpen);
  };

  const handleLogout = async () => {
    try {
      toast.loading("Logging out...");
      const url = `/api/v1/logout`; // Adjust URL based on your backend endpoint for logout

      await axios.post(url); // Assuming logout endpoint doesn't require any payload

      // Perform any necessary cleanup or state resets here
      setSuccess(false);
      setError(null);
      toast.dismiss();
      toast.success("Logout Successful");
      await fetchMe();

      navigate("/"); // Redirect to login page after logout
    } catch (error) {
      setError(true);
      toast.dismiss();
      toast.error(error.response.data.message || "Logout failed");
    }
  };

  const handleNavigation = (id) => {
    handleMenuBtn();

    if (id === "pricing" || id === "home") {
      navigate("/");
      setTimeout(() => {
        const servicesElement = document.getElementById(id);
        if (servicesElement) {
          servicesElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }  else {
      navigate(id);
     
    }
  };

  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.getElementById("nav-bar");
    const threshold = 70;
    let scrollDiff = 0;

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollChange = scrollTop - lastScrollTop;

      // Accumulate the scroll difference
      scrollDiff += scrollChange;

      // If scrolled more than the threshold, hide or show the navbar
      if (scrollDiff > threshold) {
        navbar.style.top = "-100%";
        scrollDiff = 0; // Reset scroll difference after hiding
      } else if (scrollDiff < -threshold) {
        navbar.style.top = "0%";
        scrollDiff = 0; // Reset scroll difference after showing
      }

      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, [success, error, isAuthenticated]);

  
  const logoUrl =
    theme === "dark"
      ? "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/aua02w3rslylnjvdytkt"
      : "https://res.cloudinary.com/dp5wq2vcw/image/upload/f_auto,q_auto/v1/Static/ugiosi42k0guhwie2ezg";

  return (
    <div
      style={{
        // boxShadow: " rgba(105, 10, 176, 0.7) 0px 0px 15px"
        boxShadow:
          " rgba(0, 0, 0, 0.5) 100px 700px 300px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
      }}
      id="nav-bar"
      className={`fixed bg-[#FFFFFF] dark:bg-[#020715]  flex top-0 left-0 z-[20]  lg:px-12 max-w-screen w-screen  nav-bar justify-between   px-4 py-4`}
    > 
      <button
        onClick={() => {
          navigate("/");
          setTimeout(() => {
            const servicesElement = document.getElementById("home");
            if (servicesElement) {
              servicesElement.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        }}
        className=" cursor-pointer  logo  font-bold text-3xl"
      >
        <img src={logoUrl} alt="" className="w-[150px]  " />
      </button>

      <div className={`btn  flex justify-center items-center gap-3 `}>
        <div className="mdd:hidden">
          <ModeToggle />
        </div>
        <div
          onClick={handleMenuBtn}
          className={` open-menu-button  cursor-pointer mdd:hidden w-[30px] flex flex-col gap-2 ${
            isNavBarOpen ? "hidden" : "flex"
          } `}
        >
          <div className="linee  border-t-[3px] border-black dark:border-white "></div>
          <div className="linee  border-t-[3px] border-black dark:border-white "></div>
          <div className="linee  border-t-[3px] border-black dark:border-white "></div>
        </div>

        <div
          onClick={handleMenuBtn}
          className={`close-menu-button   cross-container h-8 flex justify-center items-center cursor-pointer mdd:hidden w-[30px]  ${
            isNavBarOpen ? "block" : "hidden"
          }   mdd:hidden w-[15px] `}
        >
          <div className="line line1 bg-black dark:bg-white"></div>
          <div className="line line2 bg-black dark:bg-white "></div>
        </div>
      </div>

      <div
        className={`mobile-nav-menu px-7 dark:bg-black/75 bg-white/70 left-0 w-full absolute  mdd:hidden mt-[4.4rem] pt-12 pb-24  gap-4 
           transform transition-transform duration-500 ${
             isNavBarOpen ? "translate-y-0" : "-translate-y-[150%]"
           } mdd:hidden overflow-hidden flex flex-col py-2 border-t-[1px]  w-[90%]`}
      >
        {[
          { name: "Home  ", href: "home" },
          { name: "Services", href: "services" },
          { name: "About Us", href: "about-us" },
          { name: "Carrier Setup", href: "carrier-setup" },
          { name: "Pricing", href: "pricing" },
          { name: "contact us", href: "contactus" },
        ].map((e, idx) => (
          <div
            key={idx}
            className="group overflow-hidden  py-[2px] relative w-fit px-4"
          >
            <>
              <button
                onClick={() => {
                  handleNavigation(e.href);
                }}
                className="relative  uppercase text-xl font-sans font-semibold leading-none tracking-tighter "
              >
                {e.name}

                <span
                  className={`span1  transition-transform duration-300 ease-in-out    absolute w-full h-[4px] bg-white bottom-0 left-0`}
                ></span>

                <span
                  className={`span2  transition-transform duration-300 ease-in-out    absolute w-full  h-[4px]  bottom-0 left-0`}
                ></span>
              </button>
            </>
          </div>
        ))}

        {isAuthenticated && (
          <>
            <div className="group overflow-hidden  py-[2px] relative w-fit px-4">
              <>
                <Link
                  to={"/admin/dashboard"}
                  onClick={() => {
                    handleMenuBtn();
                  }}
                  className="relative  uppercase text-xl font-sans font-semibold leading-none tracking-tighter "
                >
                  Admin Dashboard
                  <span
                    className={`span1  transition-transform duration-300 ease-in-out    absolute w-full h-[4px]  bottom-0 left-0`}
                  ></span>
                  <span
                    className={`span2  transition-transform duration-300 ease-in-out    absolute w-full  h-[4px] bottom-0 left-0`}
                  ></span>
                </Link>
              </>
            </div>
            <div className="group overflow-hidden  py-[2px] relative w-fit px-4">
              <>
                <a
                  href={`/#`}
                  onClick={handleLogout}
                  className="relative  uppercase text-xl font-sans font-semibold leading-none tracking-tighter "
                >
                  Logout
                  <span
                    className={`span1  transition-transform duration-300 ease-in-out    absolute w-full h-[4px] e bottom-0 left-0`}
                  ></span>
                  <span
                    className={`span2  transition-transform duration-300 ease-in-out    absolute w-full  h-[4px]  bottom-0 left-0`}
                  ></span>
                </a>
              </>
            </div>
          </>
        )}
      </div>
      {/* Desktop options */}
      <div
        className={` w-full desktop-options font-light gap-3 font-['NeueMontreal'] h-[35px] mdd:flex  hidden   `}
      >
        <div className="absolute desktop-options flex top-[50%] -translate-y-[50%] left-[50%] h-[35px] w-fit -translate-x-[50%] ">
          {[
            { name: "Home", href: "home" },
            { name: "Services", href: "services" },
            { name: "About Us", href: "about-us" },
            { name: "Carrier Setup", href: "carrier-setup" },

            { name: "Pricing", href: "pricing" },
          ].map((o, idx) => {
            if (idx < 5) {
              return (
                <div
                  key={idx + 100}
                  className={`option-box relative    
                    ml-6 min-w-fit  text-[20px] overflow-hidden `}
                >
                  <div
                    className={`up-down-box   relative h-full  cursor-pointer flex justify-center   translate-y-[45%]   flex-col  transition-transform duration-300`}
                  >
                    (
                    <>
                      <button
                        onClick={() => {
                          handleNavigation(o.href);
                        }}
                      >
                        {o.name}
                      </button>
                      <button
                        onClick={() => {
                          handleNavigation(o.href);
                        }}
                      >
                        {o.name}
                      </button>{" "}
                    </>
                    )
                  </div>
                  <span
                    className={`absolute   bottom-0 -translate-x-[110%] transition-transform duration-300 ease-in-out   w-full h-[2px] `}
                  ></span>
                </div>
              );
            }
          })}
        </div>
        {/* Part 2 */}
        <div className="absolute desktop-options flex top-[50%] -translate-y-[50%] right-8   h-[35px] w-fit ">
          {[
            { name: "Home", href: "home" },
            { name: "Services", href: "services" },
            { name: "About Us", href: "about-us" },
            { name: "Carrier Setup", href: "carrier-setup" },
            { name: "Contact Us", href: "contactus" },
          ].map((o, idx) => {
            if (idx > 3) {
              return (
                <div
                  key={idx + 100}
                  className={`option-box relative 
                    ml-6 min-w-fit  text-[20px] overflow-hidden `}
                >
                  <div
                    className={`up-down-box   relative h-full  cursor-pointer flex justify-center   translate-y-[45%]   flex-col  transition-transform duration-300`}
                  >
                    (
                    <>
                      <button
                        onClick={() => {
                          handleNavigation(o.href);
                        }}
                      >
                        {o.name}
                      </button>
                      <button
                        onClick={() => {
                          handleNavigation(o.href);
                        }}
                      >
                        {o.name}
                      </button>
                    </>
                    )
                  </div>
                  <span
                    className={`absolute  ottom-0 -translate-x-[110%] transition-transform duration-300 ease-in-out   w-full h-[2px] `}
                  ></span>
                </div>
              );
            }
          })}

          <div className="ml-3 ">
            <ModeToggle />
          </div>

          {isAuthenticated && (
          <button
            className={` logout inline-flex  justify-center items-center ml-2 -mr-4 space-x-3 h-12 `}
          >
            {/* Dashboard button */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => {
                navigate("/admin/dashboard");
              }}
              className="lucide lucide-layout-dashboard"
            >
              <rect width="7" height="9" x="3" y="3" rx="1" />
              <rect width="7" height="5" x="14" y="3" rx="1" />
              <rect width="7" height="9" x="14" y="12" rx="1" />
              <rect width="7" height="5" x="3" y="16" rx="1" />
            </svg>

            {/* Logout button */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
               width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-log-out"
              onClick={handleLogout}
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" x2="9" y1="12" y2="12" />
            </svg>
          </button>
        )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
